import { Tabs, TabList, Tab, TabIndicator } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router";

import { AppRoute } from "@/AppRoute";
import { responsive } from "@/components/PageContainer/utils";
import { TestIds } from "@/utils/testIds";
import { useEffect, useState } from "react";

const academyRoutes = [
  AppRoute.Programs,
  AppRoute.BootcampsRelease,
  AppRoute.Specializations,
  AppRoute.AdvancedCourse,
  AppRoute.BootcampsAdvanced,
  AppRoute.Organizations,
  AppRoute.LiveSessionStreaming,
  AppRoute.Badges,
  AppRoute.Reports,
  AppRoute.MasterClass,
  AppRoute.LiveSessionAgoraInternal,
];

const HeaderTabs = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);

  const tabs = [
    {
      label: "Academy",
      route: AppRoute.Programs,
      data_cy: TestIds.Academy,
    },
    {
      label: "Community",
      route: AppRoute.Community,
      data_cy: TestIds.Comunity,
    },
    {
      label: "Solutions",
      route: AppRoute.Solutions,
      data_cy: TestIds.Solutions,
    },
    {
      label: "Products",
      route: AppRoute.Products,
      data_cy: TestIds.Products,
    },
    // {
    //   label: "Foro",
    //   route: AppRoute.Forum,
    //   data_cy: TestIds.Forum,
    // },
  ];

  useEffect(() => {
    const academyTabIndex = 0;
    const currentPath = location.pathname.slice(1).split("/")[0];
    const isAcademyRouteActive = academyRoutes.some((route) =>
      route.includes(currentPath)
    );

    if (isAcademyRouteActive) return setSelectedTabIndex(academyTabIndex);

    const tabIndex = tabs.findIndex((tab) => tab.route.includes(currentPath));
    return setSelectedTabIndex(tabIndex);
  }, [location.pathname]);

  return (
    <Tabs
      hideBelow={"lg"}
      index={selectedTabIndex}
      isLazy
      px={responsive.gap}
      variant={"unstyled"}
    >
      <TabList gap={{ xl: "24px" }} mt={"4px"}>
        {tabs.map((tab) => (
          <Tab
            _selected={{ fontWeight: 600, color: "black" }}
            color={"secondary.100"}
            fontFamily={"Poppins"}
            fontSize={"14px"}
            key={tab.route}
            onClick={() => navigate(tab.route)}
            data-cy={tab.data_cy}
          >
            {t(tab.label)}
          </Tab>
        ))}
      </TabList>
      {selectedTabIndex >= 0 && (
        <TabIndicator
          bgColor={"tertiary.300"}
          h={"4px"}
          mt={"5px"}
          rounded={"1px"}
        />
      )}
    </Tabs>
  );
};

export { HeaderTabs };
