import { Ico } from "@/assets/icons";
import { Box, Flex, Text } from "@chakra-ui/react";
import { CommentCardProps } from "../utils";
import { calculateTimeAgo } from "../../utils";
import { validateRole } from "../../utils";
import AvatarForum from "../../AvatarForum";
import MenuForum from "../../Menu";
import { useUser } from "@/providers/useUser";
import { UserRole } from "@/schemaTypes";

const CommentCard = ({
  comment,
  selectedPost,
  refetchPosts,
}: CommentCardProps) => {
  const rolePrivileges = validateRole(comment?.author.role as string);
  const { user, hasRoles } = useUser();
  const canEdit = hasRoles([UserRole.Admin, UserRole.Moderator]);
  let timeAgo = "Fecha desconocida";
  if (comment.createdAt) {
    timeAgo = calculateTimeAgo(comment.createdAt);
  }
  return (
    <Flex flexDirection={"column"} gap={"4px"}>
      <Flex gap={"8px"}>
        <AvatarForum author={comment.author} w={"32px"} />
        <Flex
          padding={"8px 16px"}
          flexDirection={"column"}
          gap={"4px"}
          borderRadius={"8px"}
          bg={"neutral.50"}
          width={"100%"}
        >
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Flex gap={"8px"}>
              <Text
                fontSize={"14px"}
                fontWeight={"bold"}
                color={"secondary.300"}
              >
                {comment?.author.name}
              </Text>
              {rolePrivileges && (
                <Box display={"flex"} alignItems={"center"} gap={"6px"}>
                  {comment?.author.role === "TUTOR" ? (
                    <Ico.GeneralCheckDark fontSize="20px" />
                  ) : (
                    <Ico.GeneralCheck fontSize="20px" />
                  )}
                  <Text
                    fontSize={"14px"}
                    fontWeight={"bold"}
                    color={"secondary.300"}
                  >
                    {comment?.author.role?.toLocaleLowerCase()}
                  </Text>
                </Box>
              )}
            </Flex>
            <MenuForum
              canEdit={canEdit}
              user={user}
              author={comment?.author}
              type={"Comment"}
              commentId={comment._id as string} // eslint-disable-line no-underscore-dangle
              postId={selectedPost._id as string} // eslint-disable-line no-underscore-dangle
              refetchPosts={refetchPosts}
            />
          </Flex>
          <Text
            fontFamily={"Inter"}
            fontSize={"14px"}
            fontStyle={"normal"}
            fontWeight={"400"}
            lineHeight={"22.4px"}
            textAlign={"left"}
            color={"secondary.300"}
          >
            {comment?.comment}
          </Text>
        </Flex>
      </Flex>
      <Text padding={"0px 56px"} fontSize={"12px"} color={"secondary.200"}>
        {timeAgo}
      </Text>
    </Flex>
  );
};
export default CommentCard;
