import { Button } from "@/components/Button";
import { InputField } from "@/components/Forms/InputField";
import {
  CreateMarketplaceServiceInput,
  UpdateMarketplaceServiceInput,
} from "@/schemaTypes";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Heading,
  Flex,
  Spinner,
  FormControl,
  Text,
  Box,
  IconButton,
  Input,
  Stack,
  Select,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { Trans, useTranslation } from "react-i18next";
import useValidation from "./utils";
import { FormAlert } from "@/components/Forms";
import { useLazyQuery } from "@apollo/client";
import { noCacheHeaders } from "@/utils/headers";
import { useEffect, useState } from "react";
import { Ico } from "@/assets/icons";
import { CategoriesDocument } from "../Categories/graphql/getCategories.generated";

interface CreateMarketplaceServiceModalProps {
  onClose: () => void;
  onSubmit: (
    values: CreateMarketplaceServiceInput | UpdateMarketplaceServiceInput
  ) => void;
  isFormSending: boolean;
  selectedMarketplaceService?: CreateMarketplaceServiceInput | null;
}

const CreateMarketplaceServiceModal = ({
  onClose,
  onSubmit,
  isFormSending,
  selectedMarketplaceService,
}: CreateMarketplaceServiceModalProps) => {
  const { t } = useTranslation();
  const validationSchema = useValidation();
  const [categoriesQuery, { data: categories }] = useLazyQuery(
    CategoriesDocument,
    {
      fetchPolicy: "no-cache",
      context: { headers: noCacheHeaders },
    }
  );

  const formik = useFormik({
    initialValues: {
      title: selectedMarketplaceService?.title || "",
      agency: selectedMarketplaceService?.agency || "",
      categoryId: selectedMarketplaceService?.categoryId || "",
      subcategoryId: selectedMarketplaceService?.subcategoryId || "",
      services: selectedMarketplaceService?.services || "",
      subtitle: selectedMarketplaceService?.subtitle || "",
      description: selectedMarketplaceService?.description || "",
      agencyImage: selectedMarketplaceService?.agencyImage || "",
      published: selectedMarketplaceService?.published,
      draft: selectedMarketplaceService?.draft,
      contactLink: selectedMarketplaceService?.contactLink || "",
      howItWorks: selectedMarketplaceService?.howItWorks || "",
      whyUs: selectedMarketplaceService?.whyUs || "",
    },
    onSubmit: (values) => {
      onSubmit(
        values as CreateMarketplaceServiceInput | UpdateMarketplaceServiceInput
      );
    },
    validationSchema,
  });

  const [offeredServices, setOfferedServices] = useState([""]);
  const [howItWorks, setHowItWorks] = useState([""]);

  const handleAddService = () => {
    setOfferedServices([...offeredServices, ""]);
  };
  const handleAddHowItWorks = () => {
    setHowItWorks([...howItWorks, ""]);
  };
  const handleRemoveService = (index: number) => {
    const newServices = offeredServices.filter((_, i) => i !== index);
    setOfferedServices(newServices);
  };

  const handleServiceChange = (index: number, value: string) => {
    const newServices = [...offeredServices];
    newServices[index] = value;
    setOfferedServices(newServices);
  };

  const handleRemoveHowItWorks = (index: number) => {
    const newHowItWorks = howItWorks.filter((_, i) => i !== index);
    setHowItWorks(newHowItWorks);
  };

  const handleHowItWorksChange = (index: number, value: string) => {
    const newHowItWorks = [...howItWorks];
    newHowItWorks[index] = value;
    setHowItWorks(newHowItWorks);
  };

  useEffect(() => {
    formik.values.services = offeredServices;
  }, [offeredServices]);

  useEffect(() => {
    formik.values.howItWorks = howItWorks;
  }, [howItWorks]);

  useEffect(() => {
    categoriesQuery();
  }, []);

  useEffect(() => {
    setOfferedServices(
      selectedMarketplaceService?.services &&
        selectedMarketplaceService?.services.length > 0
        ? (selectedMarketplaceService?.services as Array<string>)
        : [""]
    );
    setHowItWorks(
      selectedMarketplaceService?.howItWorks &&
        selectedMarketplaceService?.howItWorks.length > 0
        ? (selectedMarketplaceService?.howItWorks as Array<string>)
        : [""]
    );
  }, [selectedMarketplaceService]);

  const handleDraftSave = () => {
    formik.values.draft = true;
    formik.values.published = false;
    return formik.submitForm();
  };

  const handlePublish = () => {
    formik.values.draft = false;
    formik.values.published = true;
    return formik.submitForm();
  };

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading>
            <Trans>
              {selectedMarketplaceService
                ? "Editar Servicio"
                : "Crear Servicio"}
            </Trans>
            {formik.values.draft ? (
              <Flex>
                <br></br>{" "}
                <Button isDisabled variant="text">
                  Borrador
                </Button>
              </Flex>
            ) : null}
            {formik.values.published ? (
              <Flex>
                <br></br>{" "}
                <Button isDisabled variant="text">
                  Publicado
                </Button>
              </Flex>
            ) : null}
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex as={"form"} gap={5} flexWrap={"wrap"}>
            <FormControl>
              <InputField
                isRequired
                name="title"
                label={t("Titulo del servicio")}
                onChange={formik.handleChange("title")}
                onPaste={formik.handleChange("title")}
                value={formik.values.title}
              />
              <FormAlert>{formik.errors.title}</FormAlert>
            </FormControl>
            <FormControl>
              <InputField
                isRequired
                name="agency"
                label={t("Nombre de la agencia")}
                onChange={formik.handleChange("agency")}
                onPaste={formik.handleChange("agency")}
                value={formik.values.agency}
              />
              <FormAlert>{formik.errors.agency}</FormAlert>
            </FormControl>
            <FormControl>
              <Text fontSize="sm" mb={2} fontWeight={600}>
                Categoria principal
              </Text>
              <Select
                value={formik.values.categoryId}
                isRequired
                onChange={formik.handleChange("categoryId")}
                placeholder="Selecciona una categoria principal"
              >
                {categories?.categories.map((cat) => {
                  return (
                    <option key={cat.id} value={cat.id}>
                      {cat.name}
                    </option>
                  );
                })}
              </Select>
              <FormAlert>{formik.errors.categoryId}</FormAlert>
            </FormControl>
            <FormControl>
              <Text fontSize="sm" mb={2} fontWeight={600}>
                Subcategoria
              </Text>
              <Select
                value={formik.values.subcategoryId}
                isRequired
                onChange={formik.handleChange("subcategoryId")}
                placeholder="Selecciona una subcategoria"
              >
                {categories?.categories.map((cat) => {
                  return (
                    <option key={cat.id} value={cat.id}>
                      {cat.name}
                    </option>
                  );
                })}
              </Select>
              <FormAlert>{formik.errors.subcategoryId}</FormAlert>
            </FormControl>
            <FormControl>
              <Box>
                <Text fontSize="sm" mb={1} fontWeight={600}>
                  Servicios ofrecidos
                </Text>
                <Stack spacing={3}>
                  {offeredServices.map((service, index) => (
                    <Box key={index} display="flex" alignItems="center">
                      <Input
                        value={service}
                        onChange={(e) =>
                          handleServiceChange(index, e.target.value)
                        }
                        placeholder={`Servicio ${index + 1}`}
                      />
                      <IconButton
                        fontSize={"14px"}
                        aria-label="Remove service"
                        variant={"primary"}
                        icon={<Ico.Close></Ico.Close>}
                        ml={2}
                        onClick={() => handleRemoveService(index)}
                        isDisabled={offeredServices.length === 1}
                      />
                    </Box>
                  ))}
                </Stack>

                <Button
                  w={{ base: "auto", lg: "max-content" }}
                  mt="16px"
                  title={t("Agregar servicio")}
                  variant="primary"
                  onClick={handleAddService}
                  p={1}
                  leftIcon={
                    <Ico.Plus fontSize={"20px"} color="neutral.white" />
                  }
                />
              </Box>
            </FormControl>
            <FormControl>
              <InputField
                isRequired
                name="subtitle"
                label={t("Subtitulo")}
                onChange={formik.handleChange("subtitle")}
                onPaste={formik.handleChange("subtitle")}
                value={formik.values.subtitle}
              />
              <FormAlert>{formik.errors.subtitle}</FormAlert>
            </FormControl>
            <FormControl>
              <InputField
                isRequired
                name="description"
                label={t("Descripcion")}
                onChange={formik.handleChange("description")}
                onPaste={formik.handleChange("description")}
                value={formik.values.description}
              />
              <FormAlert>{formik.errors.description}</FormAlert>
            </FormControl>
            <FormControl>
              <InputField
                isRequired
                name="agencyImage"
                label={t("Imagen de la Agencia")}
                onChange={formik.handleChange("agencyImage")}
                onPaste={formik.handleChange("agencyImage")}
                value={formik.values.agencyImage}
              />
              <FormAlert>{formik.errors.agencyImage}</FormAlert>
            </FormControl>
            <FormControl>
              <InputField
                isRequired
                name="whyUs"
                label={t("¿Por qué nosotros?")}
                onChange={formik.handleChange("whyUs")}
                onPaste={formik.handleChange("whyUs")}
                value={formik.values.whyUs}
              />
              <FormAlert>{formik.errors.whyUs}</FormAlert>
            </FormControl>
            <FormControl>
              <InputField
                isRequired
                name="contactLink"
                label={t("Link de contacto")}
                onChange={formik.handleChange("contactLink")}
                onPaste={formik.handleChange("contactLink")}
                value={formik.values.contactLink}
              />
              <FormAlert>{formik.errors.contactLink}</FormAlert>
            </FormControl>
            <FormControl>
              <Box>
                <Text fontSize="sm" mb={1} fontWeight={600}>
                  Como funciona?
                </Text>
                <Stack spacing={3}>
                  {howItWorks.map((service, index) => (
                    <Box key={index} display="flex" alignItems="center">
                      <Input
                        value={service}
                        onChange={(e) =>
                          handleHowItWorksChange(index, e.target.value)
                        }
                        placeholder={`Como funciona ${index + 1}`}
                      />
                      <IconButton
                        fontSize={"14px"}
                        aria-label="Remove how it works"
                        variant={"primary"}
                        icon={<Ico.Close></Ico.Close>}
                        ml={2}
                        onClick={() => handleRemoveHowItWorks(index)}
                        isDisabled={howItWorks.length === 1}
                      />
                    </Box>
                  ))}
                </Stack>

                <Button
                  w={{ base: "auto", lg: "max-content" }}
                  mt="16px"
                  title={t("Agregar como funciona")}
                  variant="primary"
                  onClick={handleAddHowItWorks}
                  p={1}
                  leftIcon={
                    <Ico.Plus fontSize={"20px"} color="neutral.white" />
                  }
                />
              </Box>
            </FormControl>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Flex
            w={"100%"}
            alignItems={"space-between"}
            direction={"column"}
            gap={4}
          >
            {!formik.isValid && (
              <Text variant={"caption"}>
                No se puede enviar el formulario si tiene errores
              </Text>
            )}
            <Button
              title={t("Cancel")}
              variant="secondary"
              mr={3}
              onClick={onClose}
            />
            <Button
              isDisabled={!formik.isValid}
              title={t("Guardar como borrador")}
              variant="primary"
              onClick={handleDraftSave}
              leftIcon={isFormSending ? <Spinner /> : undefined}
            />
            <Button
              isDisabled={!formik.isValid}
              title={t("Publicar")}
              variant="primary"
              onClick={handlePublish}
              leftIcon={isFormSending ? <Spinner /> : undefined}
            />
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateMarketplaceServiceModal;
