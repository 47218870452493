import { useMemo, useEffect } from "react";
import { GrowthBook } from "@growthbook/growthbook-react";
import { config } from "@/config";
import { useUser } from "@/providers/useUser";
import { useMutation } from "@apollo/client";
import { SyncABdataDocument } from "@/graphql/syncABdata.generated";

const useGrowthBook = () => {
  const { user } = useUser();
  const [syncABdata] = useMutation(SyncABdataDocument);
  const growthbook = useMemo(() => {
    const gb = new GrowthBook({
      apiHost: config.VITE_GROWTHBOOK_API_HOST,
      clientKey: config.VITE_GROWTHBOOK_CLIENT_KEY,
      enableDevMode: true,
      attributes: {
        id: user?.id,
      },
      trackingCallback: (experiment, result) => {
        // eslint-disable-next-line no-console
        console.log("Experiment Viewed", {
          experimentId: experiment.key,
          variationId: result.key,
          result: result,
          experiment: experiment,
          user: user?.id,
        });
        syncABdata({
          variables: {
            syncABdataInput: { key: experiment.key, value: result.value },
          },
        });
      },
    });
    gb.init({
      streaming: true,
    });
    return gb;
  }, [user?.id]);

  useEffect(() => {
    const unixTimestamp = Math.floor(
      new Date(user?.createdAt ?? "").getTime() / 1000
    );
    if (user) {
      growthbook.setAttributes({ id: user?.id, memberSince: unixTimestamp });
      growthbook.refreshFeatures();
    }
  }, [user, growthbook]);

  return growthbook;
};

export default useGrowthBook;
