import { useEffect, useRef } from "react";
import {
  Textarea,
  FormControl,
  Spinner,
  Flex,
  useBreakpointValue,
  Text,
  Box,
} from "@chakra-ui/react";
import { Ico } from "@/assets/icons";
import {
  CreateCommentProps,
  useCommentValidation,
  useInitialValues,
} from "../utils";
import { CommentInput, CreateNewNotificationInput } from "@/schemaTypes";
import { useFormik } from "formik";
import { CreateOrUpdateCommentDocument } from "./graphql/CreateOrUpdateComment.generated";
import { useMutation } from "@apollo/client";
import { CreateNewNotificationDocument } from "./graphql/CreateNewNotification.generated";
import { useUser } from "@/providers/useUser";
import { SBErrorPubSub } from "@/utils/errors/SBError";
import { getHighestRole } from "../../utils";
import { Button } from "@/components/Button";
import AvatarForum from "../../AvatarForum";

const CreateComment = ({
  selectedComment,
  selectedPost,
  refetchPosts,
}: CreateCommentProps) => {
  const { user } = useUser();
  const role = getHighestRole(user?.roles || []);

  const [createComment, { loading: isCreateLoading }] = useMutation(
    CreateOrUpdateCommentDocument
  );
  const [createNewNotification] = useMutation(CreateNewNotificationDocument);

  const newNotification = async () => {
    try {
      const dataNotification: CreateNewNotificationInput = {
        userId: selectedPost.author.id,
        notificationRecords: {
          author: {
            id: user?.id,
            name: user?.name + " " + user?.lastName,
            role: role,
          },
          description: {
            eventId: selectedPost._id, // eslint-disable-line no-underscore-dangle
            eventType: "comment",
            eventName: selectedPost.content,
            descriptionEvent: "Commented your post",
            userEventId: selectedPost.author.id,
            userName: selectedPost.author.name,
          },
        },
      };
      await createNewNotification({
        variables: {
          createNewNotificationInput: dataNotification,
        },
      });
    } catch (error) {
      const errorMessage = (error as Error).message;
      SBErrorPubSub.publish({
        component: "MenssageInput/index.tsx",
        message: errorMessage || "Error create new notification",
        showInProd: true,
      });
    }
  };

  const validationSchema = useCommentValidation();
  const initialValues = useInitialValues(
    selectedPost,
    selectedComment || undefined
  );
  const handleSubmit = (values: CommentInput) => {
    createComment({
      variables: {
        commentInput: values,
      },
    }).then((response) => {
      if (response.data?.createOrUpdateComment) {
        refetchPosts();
        newNotification();
      }
    });
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const castedValues = validationSchema.cast(values);
      handleSubmit(castedValues as CommentInput);
      formik.resetForm();
    },
  });
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const textareaContainerRef = useRef<HTMLDivElement>(null);
  const textareaHeight = useBreakpointValue({ base: "20px", lg: "23px" });

  useEffect(() => {
    if (textareaRef.current && textareaContainerRef.current) {
      textareaRef.current.style.height = "23px";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      textareaContainerRef.current.style.height = `${
        textareaRef.current.scrollHeight + 25
      }px`;
    }
  }, [formik.values.comment, textareaHeight]);

  return (
    <Flex gap={"12px"} w={"100%"}>
      <AvatarForum w={"32px"} />
      <Flex flexDir={"column"} w={"100%"} gap={1} alignItems={"end"}>
        <FormControl
          ref={textareaContainerRef}
          alignItems={"flex-end"}
          display={"flex"}
          borderRadius={"6px"}
          border={"1px solid"}
          outline={"1px transparent"}
          borderColor={"neutral.300"}
          outlineOffset={"none"}
          _focusWithin={{
            border: "1px solid",
            outline: "1px solid",
            borderColor: "primary.500",
          }}
          height={"48px"}
          maxHeight="335px"
          py={"10px"}
          px={4}
          as="form"
          gap={2}
        >
          <Textarea
            ref={textareaRef}
            placeholder="Añadir comentario"
            minHeight={textareaHeight}
            maxHeight="314px"
            value={formik.values.comment}
            onChange={formik.handleChange("comment")}
            onPaste={formik.handleChange("comment")}
            onBlur={formik.handleBlur("comment")}
            border="none"
            py={0}
            px={0}
            _focus={{ borderColor: "transparent" }}
            style={{
              borderBottom: "none",
              resize: "none",
            }}
            maxLength={2000}
            overflowWrap="break-word"
            sx={{
              "&::placeholder": {
                textAlign: "start",
                color: "secondary.50",
              },
              "&::-webkit-scrollbar": { width: "4px", height: "20px" },
            }}
          />

          <Button
            onClick={() => formik.submitForm()}
            isDisabled={!formik.isValid}
            aria-label="Enviar mensaje"
            minW={"28px"}
            minH={"28px"}
            display="flex"
            p={0}
            px={0}
            borderRadius="100%"
            variant="primary"
          >
            {isCreateLoading ? (
              <Spinner />
            ) : (
              <Box>
                <Ico.SendIncline fontSize={"20px"} />
              </Box>
            )}
          </Button>
        </FormControl>
        <Text display={{ base: "flex", lg: "flex" }} fontSize="sm">
          <Text
            fontSize="sm"
            color={
              formik.values.comment.length >= 2000 ? "red.500" : "secondary.100"
            }
          >
            {formik.values.comment.length}
          </Text>{" "}
          /2000
        </Text>
      </Flex>
    </Flex>
  );
};

export default CreateComment;
