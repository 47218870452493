export const config = {
  TZ_KEY: "ada-TZ",
  JWT_KEY: "ada-JWT",
  AGORA_TOKEN: "agora-token",
  JWT_EXPIRATION_KEY: "ada-JWT-EXP",
  RT_KEY: "ada-RT",
  EDIT_MODE: "ada-EditMode",
  IPBASE_KEY: import.meta.env.VITE_APP_IPBASE_KEY || "",
  VITE_SEGMENT_KEY: import.meta.env.VITE_SEGMENT_KEY,
  PRO_MODE: "ada-ProMode",
  GRAPHQL_URL:
    import.meta.env.VITE_GRAPHQL_URL || "http://localhost:4000/graphql",
  AUTH_API_URL:
    import.meta.env.VITE_AUTH_API_URL || "http://localhost:4000/v1/auth",
  APP_VERSION: import.meta.env.VITE_VERSION || "1",
  FT_KEY: "ada-FT",
  VITE_APP_ENV: import.meta.env.VITE_APP_ENV || "development",
  ADA_ENV: import.meta.env.VITE_ADA_ENV || "main",
  USE_LOCAL_AVATARS: import.meta.env.VITE_USE_LOCAL_AVATARS || false,
  PUBLIC_URL: import.meta.env.PUBLIC_URL || "",
  APP_COMMIT_REF: import.meta.env.COMMIT_REF || "",
  AWS_ACCESSKEYID_EVIDENTLY: import.meta.env.AWS_ACCESSKEYID_EVIDENTLY || "",
  AWS_SECRETACCESSKEY_EVIDENTLY:
    import.meta.env.AWS_SECRETACCESSKEY_EVIDENTLY || "",
  AWS_PROJECTNAME_EVIDENTLY: import.meta.env.AWS_PROJECTNAME_EVIDENTLY || "",
  AWS_ENDPOINT_EVIDENTLY: import.meta.env.AWS_ENDPOINT_EVIDENTLY || "",
  AWS_REGION_EVIDENTLY: import.meta.env.AWS_REGION_EVIDENTLY || "us-east-2",
  AWS_BUCKET_EVIDENTLY: import.meta.env.AWS_BUCKET_EVIDENTLY || "",
  AWS_FEATURE_EVIDENTLY: import.meta.env.AWS_FEATURE_EVIDENTLY || "",
  VITE_GROWTHBOOK_API_HOST: import.meta.env.VITE_GROWTHBOOK_API_HOST || "",
  VITE_GROWTHBOOK_CLIENT_KEY: import.meta.env.VITE_GROWTHBOOK_CLIENT_KEY || "",
  VITE_GROWTHBOOK_ENV: import.meta.env.VITE_GROWTHBOOK_ENV || "development",
  VITE_SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN || "",
  DATADOG_APPLICATION_ID: import.meta.env.VITE_DATADOG_APPLICATION_ID || "",
  DATADOG_CLIENT_TOKEN: import.meta.env.VITE_DATADOG_CLIENT_TOKEN || "",
  DATADOG_ENV: import.meta.env.VITE_DATADOG_ENV || "",
  DATADOG_SERVICE: import.meta.env.VITE_DATADOG_SERVICE || "",
  PORTAL_URL: import.meta.env.PORTAL_URL || "http://localhost:3000",
  RECAPTCHA_SITE_KEY: import.meta.env.VITE_RECAPTCHA_SITE_KEY || "",
  VITE_OPTIMIZELY_SDK_KEY: import.meta.env.VITE_OPTIMIZELY_SDK_KEY || "",
  APP_ID_AGORA: import.meta.env.VITE_APP_ID_AGORA || "",
  ROCKETFY_API_KEY: import.meta.env.VITE_ROCKETFY_API_KEY || "",
  ROCKETFY_API_SECRET: import.meta.env.VITE_ROCKETFY_API_SECRET || "",
  ROCKETFY_API_URL: import.meta.env.VITE_ROCKETFY_API_URL || "",
  YOUTUBE_API_KEY: import.meta.env.VITE_YOUTUBE_API_KEY || "",
  VITE_YOUTUBE_PLAYLIST_ENDPOINT_URL:
    import.meta.env.VITE_YOUTUBE_PLAYLIST_ENDPOINT_URL ||
    "https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=",
  APP_KEY_AGORA_REST: import.meta.env.VITE_APP_KEY_AGORA_REST || "",
  APP_SECRET_AGORA_REST: import.meta.env.VITE_APP_SECRET_AGORA_REST || "",
  SALESFORCE_CHAT_ORGID: import.meta.env.VITE_SALESFORCE_CHAT_ORGID || "",
  SALESFORCE_CHAT_DEPLOYMENTID:
    import.meta.env.VITE_SALESFORCE_CHAT_DEPLOYMENTID || "",
  SALESFORCE_CHAT_BUTTONID: import.meta.env.VITE_SALESFORCE_CHAT_BUTTONID || "",
  VITE_GTM: import.meta.env.VITE_GTM || "",
  VITE_HOTJAR_ID: import.meta.env.VITE_HOTJAR_ID || "",
  VITE_HOTJAR_VERSION: import.meta.env.VITE_HOTJAR_VERSION || "",
  VITE_HEAP_ANALYTICS_APP_ID: import.meta.env.VITE_HEAP_ANALYTICS_APP_ID || "",
  VITE_GA4: import.meta.env.VITE_GA4 || "G-XXXXX",
  VITE_TZ_API_KEY:
    import.meta.env.VITE_TZ_API_KEY ||
    "AIzaSyAGipH5aq1Dg4iEFLyhiDhpp_faXdeplsQ",
  SDK_KEY_ZOOM: import.meta.env.VITE_SDK_KEY_ZOOM || "",
  SDK_SECRET_ZOOM: import.meta.env.VITE_SDK_SECRET_ZOOM || "",
  FIREBASE_API_KEY: import.meta.env.VITE_FIREBASE_API_KEY || "",
  FIREBASE_AUTH_DOMAIN: import.meta.env.FIREBASE_AUTH_DOMAIN || "",
  FIREBASE_PROJECT_ID: import.meta.env.VITE_FIREBASE_PROJECT_ID || "",
  FIREBASE_STORAGE_BUCKET: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET || "",
  FIREBASE_MESSAGING_SENDER_ID:
    import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID || "",
  FIREBASE_APP_ID: import.meta.env.VITE_FIREBASE_APP_ID || "",
  FIREBASE_MEASUREMENT_ID: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID || "",
  VITE_COMMUNITY_WHATSAPP_MAGIC_LINK_AMAZON:
    import.meta.env.VITE_COMMUNITY_WHATSAPP_MAGIC_LINK_AMAZON || "",
  VITE_COMMUNITY_WHATSAPP_MAGIC_LINK_MELI:
    import.meta.env.VITE_COMMUNITY_WHATSAPP_MAGIC_LINK_MELI || "",
  VITE_COMMUNITY_WHATSAPP_MAGIC_LINK_DROPSHIPPING:
    import.meta.env.VITE_COMMUNITY_WHATSAPP_MAGIC_LINK_DROPSHIPPING || "",

  VITE_COMMUNITY_WHATSAPP_MAGIC_LINK_DROPSHIPPING_SOCIAL_MEDIA:
    import.meta.env
      .VITE_COMMUNITY_WHATSAPP_MAGIC_LINK_DROPSHIPPING_SOCIAL_MEDIA || "",

  VITE_COMMUNITY_WHATSAPP_MAGIC_LINK_AMAZON_ARBITRATION:
    import.meta.env.VITE_COMMUNITY_WHATSAPP_MAGIC_LINK_AMAZON_ARBITRATION || "",

  PORTAL_WEBSOCKET:
    import.meta.env.PORTAL_WEBSOCKET || "ws://localhost:4000/graphql",
};
