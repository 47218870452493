import {
  Box,
  Flex,
  IconButton,
  Image,
  Show,
  useColorModeValue,
} from "@chakra-ui/react";

import { useNavigate, useLocation } from "react-router-dom";

import { AppRoute } from "@/AppRoute";
import { Ico } from "@/assets/icons";
import { GamificationStats } from "@/components/Gamification/GamificationStats";
import { AlertSubscription } from "@/components/SuscriptionExpired/AlertSubscription";
import { useUser } from "@/providers/useUser";

import mainLogo from "/img/logo_smartbeemo.svg";
import { HeaderMenu } from "./Menu";
import { HeaderTabs } from "./Tabs";
import { HeaderProps } from "./types";
import { ConsultingButton } from "../Consulting";
import { TestIds } from "@/utils/testIds";
import { NotificationsMenu } from "./Notifications";

const Header = ({ onClickMenuButton, sideBar }: HeaderProps): JSX.Element => {
  const { user, paymentMethodLink, lastPaymentDeclined } = useUser();

  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const color = useColorModeValue("secondary.300", "white");
  const hoverColor = useColorModeValue("secondary.400", "white");
  const imgBgColor = useColorModeValue("unset", "neutral.200");
  const location = useLocation();
  const navigate = useNavigate();

  const redirect = (path: string) => () => {
    navigate(path);
    window.location.reload();
  };

  function isCurrentPath(pathToCheck: string) {
    return location.pathname === pathToCheck;
  }

  const px = { base: "16px", md: "24px" };
  const subscriptionStatus = lastPaymentDeclined;

  return (
    <>
      {subscriptionStatus ? (
        <AlertSubscription updatePaymentMethodLink={paymentMethodLink} />
      ) : null}
      <Flex
        align={"center"}
        as={"header"}
        bgColor={bgColor}
        borderBottom={"1px"}
        borderColor={borderColor}
        color={color}
        h={"var(--header-height)"}
        justify={"space-between"}
        overflow={"none"}
        pos={"sticky"}
        top={0}
        w={"100%"}
        zIndex={1001}
      >
        <Flex pl={px} w={"270px"}>
          {sideBar !== "hide" ? (
            <IconButton
              _hover={{ color: hoverColor }}
              aria-label={"Menu"}
              bgColor={"transparent"}
              color={"inherit"}
              display={"flex"}
              hideFrom={"lg"}
              icon={
                <Box
                  as={sideBar === "open" ? Ico.Times : Ico.Bars}
                  fontSize={"24px"}
                />
              }
              onClick={onClickMenuButton}
              width={"56px"}
            />
          ) : null}
          <Image
            alt={"mainLogo"}
            bgColor={imgBgColor}
            cursor={"pointer"}
            onClick={redirect(AppRoute.Base)}
            p={"4px"}
            pos={"relative"}
            rounded={"8px"}
            src={mainLogo}
            w={"182px"}
            data-cy={TestIds.HomeButton}
          />
        </Flex>
        <Box flex={1}>{!isCurrentPath(AppRoute.Base) && <HeaderTabs />}</Box>
        <Flex align={"center"} gap={4} pr={px}>
          <ConsultingButton isCard={false} />
          <Show above={"xl"}>{user && <GamificationStats />}</Show>
          <NotificationsMenu onToggleBlur={() => {}} />
          <HeaderMenu onToggleBlur={() => {}} />
        </Flex>
      </Flex>
    </>
  );
};

export { Header };
