import { Ico } from "@/assets/icons";
import {
  Button as ChakraButton,
  Image,
  ChakraProps,
  IconProps,
  Box,
} from "@chakra-ui/react";
import React from "react";
import { Trans } from "react-i18next";

const buttonStyles = {
  primary: {
    base: {
      backgroundColor: "primary.500",
      color: "white",
      borderRadius: "8px",
    },
    hover: {
      backgroundColor: "primary.200",
    },
    focus: {
      backgroundColor: "primary.500",
      boxShadow: "none",
      outline: "none",
    },
    active: {
      backgroundColor: "primary.400",
    },
    disabled: {
      backgroundColor: "neutral.100",
      color: "gray.500",
    },
    loading: {
      bgColor: "primary.50",
      color: "white",
    },
  },
  secondary: {
    base: {
      color: "primary.300",
      border: "2px solid",
      bgColor: "transparent",
      borderColor: "primary.300",
      borderRadius: "8px",
    },
    hover: {
      borderColor: "primary.200",
      color: "primary.200",
      border: "2px solid",
    },
    focus: {
      color: "primary.500",
      border: "1px solid ",
      borderColor: "primary.500",
      boxShadow: "none",
      outline: "none",
    },
    active: {
      color: "primary.400",
      border: "1px solid",
      bordercolor: "secondary.300",
    },
    disabled: {
      backgroundColor: "neutral.100",
      color: "gray.500",
    },
    loading: {
      borderColor: "primary.50",
      color: "primary.300",
    },
  },
  link: {
    base: {
      backgroundColor: "transparent",
      color: "primary.500",
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    hover: {
      color: "primary.200",
    },
    focus: {
      boxShadow: "none",
      outline: "none",
    },
    active: {
      color: "primary.400",
    },
    disabled: {
      color: "gray.500",
    },
    loading: {
      color: "primary.300",
    },
  },
  option: {
    base: {
      px: "5px",
      py: "5px",
      fontSize: "14px",
      color: "secondary.400",
      border: "1px solid",
      bgColor: "transparent",
      borderColor: "neutral.200",
      borderRadius: "8px",
    },
    hover: {
      bg: "neutral.50",
      border: "1px solid",
      borderColor: "neutral.100",
      color: "secondary.200",
    },
    focus: {
      bg: "neutral.50",
      border: "1px solid",
      borderColor: "neutral.100",
      color: "secondary.200",
    },
    active: {
      bgColor: "secondary.400",
      color: "neutral.50",
      border: "1px solid",
      bordercolor: "secondary.300",
    },
    disabled: {
      backgroundColor: "neutral.100",
      color: "gray.500",
    },
    loading: {
      borderColor: "primary.50",
      color: "primary.300",
    },
  },
  text: {
    base: {
      backgroundColor: "transparent",
      color: "secondary.300",
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    hover: {
      color: "secondary.100",
    },
    focus: {
      boxShadow: "none",
      outline: "none",
    },
    active: {
      color: "secondary.400",
    },
    disabled: {
      color: "gray.500",
    },
    loading: {
      color: "secondary.300",
    },
  },
  salesforceChat: {
    base: {
      backgroundColor: "tertiary.500",
      color: "black",
      borderRadius: "8px",
    },
    hover: {
      backgroundColor: "tertiary.200",
    },
    focus: {
      backgroundColor: "tertiary.500",
      boxShadow: "none",
      outline: "none",
    },
    active: {
      backgroundColor: "tertiary.400",
    },
    disabled: {
      backgroundColor: "neutral.100",
      color: "gray.500",
    },
    loading: {
      bgColor: "tertiary.50",
      color: "white",
    },
  },
};

type buttonStylesType = keyof typeof buttonStyles;

type ButtonProps = {
  title?: string;
  onClick?: (() => void) | ((event: React.MouseEvent) => void) | undefined;
  variant?: buttonStylesType;
  hidden?: boolean;
  isSelected?: boolean;
  leftIcon?: string | null | React.ReactNode;
  rightIcon?: string | null | React.ReactNode;
  colorScheme?: string;
  size?: string;
  type?: "button" | "submit" | "reset";
  isLoading?: boolean;
  isDisabled?: boolean;
  spinnerPlacement?: "start" | "end";
  iconRigh?: React.ReactElement<IconProps, string> | undefined;
  loadingText?: string;
  children?: React.ReactNode;
  ref?: React.Ref<HTMLButtonElement>;
} & ChakraProps;

const Button: React.FC<ButtonProps> = ({
  title,
  onClick,
  type,
  variant,
  isLoading,
  hidden,
  isSelected,
  colorScheme,
  size,
  isDisabled,
  spinnerPlacement,
  iconRigh,
  leftIcon,
  rightIcon,
  loadingText,
  children,
  ref,
  ...rest
}) => {
  if (!variant) {
    variant = "primary";
  }
  const buttonStyle = buttonStyles[variant];
  return (
    <ChakraButton
      onClick={onClick && onClick}
      {...buttonStyle.base}
      _hover={buttonStyle.hover}
      _focus={buttonStyle.focus}
      _active={buttonStyle.active}
      _disabled={buttonStyle.disabled}
      _loading={buttonStyle.loading}
      rightIcon={iconRigh}
      isLoading={isLoading}
      isActive={isSelected}
      size={size}
      type={type}
      isDisabled={isDisabled}
      loadingText={loadingText}
      spinnerPlacement={spinnerPlacement}
      hidden={hidden}
      colorScheme={colorScheme}
      spinner={<Ico.Spinner fontSize={"2px"} color={"white"} />}
      ref={ref}
      {...rest}
    >
      {leftIcon && typeof leftIcon === "string" ? (
        <Image mr={2} src={leftIcon} />
      ) : leftIcon ? (
        <Box mr={2}>{leftIcon}</Box>
      ) : null}
      <Trans>{children || title}</Trans>
      {rightIcon && typeof rightIcon === "string" ? (
        <Image ml={2} src={rightIcon} />
      ) : rightIcon ? (
        <Box ml={2}>{rightIcon}</Box>
      ) : null}
    </ChakraButton>
  );
};

export default Button;
