/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import type { Dict } from "@chakra-ui/utils";
import { t } from "i18next";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { theme as defaultTheme } from "@/components/Theme";
import { config } from "@/config";
import { useDebouncedValue } from "@/hooks/useDebouncedValue";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import { School, UpdateUserInput, User, UserRole } from "@/schemaTypes";
import DateTimeInterface, {
  GeoLocation,
} from "@/utils/dateTimeFormat/dateTimeInteface";
import DateTimeMoment from "@/utils/dateTimeFormat/dateTimeMoment";
import { SBErrorPubSub } from "@/utils/errors/SBError";
import { FirebaseApp, initializeApp } from "firebase/app";
import { firebaseConfig } from "@/firebaseConfig";
import { useQuery } from "@apollo/client";
import { GetMyOrdersDocument } from "@/pages/MyOrders/graphql/getMyOrders.generated";
import { SearchProgramsDocument } from "@/pages/academy/Programs/graphql/searchPrograms.generated";

export type UserState = Pick<
  User,
  | "id"
  | "name"
  | "lastName"
  | "email"
  | "avatarUrl"
  | "roles"
  | "gitHubUsername"
  | "unboxing"
  | "forumStandards"
  | "birthDate"
  | "phone"
  | "website"
  | "proUpdatedAt"
  | "communityWhatsApp"
  | "countryData"
  | "salesforceID"
  | "createdAt"
> | null;

type SchoolState = Pick<
  School,
  "id" | "name" | "logoUrl" | "linkedInId"
> | null;

export type communityWhatsApp = {
  amazon: boolean;
  meli: boolean;
  dropshipping: boolean;
};

export type UserHook = {
  user: UserState;
  isEditModeEnabled: boolean;
  isProModeEnabled: boolean;
  theme: Dict;
  setUser: Dispatch<SetStateAction<UserState>>;
  setTheme: Dispatch<SetStateAction<Dict>>;
  currentSchool: SchoolState;
  setCurrentSchool: Dispatch<SetStateAction<SchoolState>>;
  setUserCoins: Dispatch<SetStateAction<number>>;
  userCoins: number;
  userLevel: number;
  setUserLevel: Dispatch<SetStateAction<number>>;
  setUserExperience: Dispatch<SetStateAction<number>>;
  userExperience: number;
  setIsEditModeEnabled: Dispatch<SetStateAction<boolean>>;
  setIsProModeEnabled: Dispatch<SetStateAction<boolean>>;
  isAdmin?: boolean;
  timezone: string;
  setTimezone: (timezone: string) => void;
  hasRoles: (roles: Array<UserRole>) => boolean;
  isMenuVisible?: boolean;
  setIsMenuVisible: Dispatch<SetStateAction<boolean>>;
  recentCoursesIds: Array<string>;
  addRecentCourse: (courseId: string) => void;
  refetchUserStats: (delay?: number) => void;
  isUnlockedForUser: (requiredLevel: number) => boolean;
  lastUserStatsUpdate: string;
  geolocation: GeoLocation;
  communityWhatsApp: communityWhatsApp;
  firebaseAnalyticsApp: FirebaseApp | undefined;
  lastPaymentDeclined: boolean;
  paymentMethodLink: string;
  setLastPaymentDeclined: Dispatch<SetStateAction<boolean>>;
  setpaymentMethodLink: Dispatch<SetStateAction<string>>;
  programsToDeprecateTitles: Array<string>;
  deprecatedProgramsUntil: string;
  setProgramsToDeprecateTitles: Dispatch<SetStateAction<Array<string>>>;
  setAvailableUntilDate: Dispatch<SetStateAction<string>>;
};

export const UserContext = React.createContext<UserHook | null>(null);

const RECENT_COURSES_KEY = "ada-RecentCourses";

export const UserProvider: React.FC<{ children?: React.ReactNode }> = (
  props
) => {
  const [storedEditMode, setStoredEditMode] = useLocalStorage(
    config.EDIT_MODE,
    false
  );
  const [storedTimezone, setStoredTimezone] = useLocalStorage("d", "local");
  const [user, setUser] = React.useState<any>();
  const [theme, setTheme] = React.useState<Dict>(defaultTheme);
  const [currentSchool, setCurrentSchool] = React.useState<SchoolState>(null);
  const [storeRecentCoursesIds, setStoreRecentCoursesIds] = useLocalStorage<
    Array<string>
  >(RECENT_COURSES_KEY, []);
  const [recentCoursesIds, setRecentCoursesIds] = useState<Array<string>>(
    storeRecentCoursesIds
  );
  const [isMenuVisible, setIsMenuVisible] = React.useState<boolean>(true);
  const [isEditModeEnabled, setIsEditModeEnabled] =
    React.useState<boolean>(storedEditMode);
  const [timezone, setTimezone] = React.useState<string>(
    storedTimezone || "local"
  );
  const [lastPaymentDeclined, setLastPaymentDeclined] =
    useState<boolean>(false);
  const [programsToDeprecateTitles, setProgramsToDeprecateTitles] = useState<
    Array<string>
  >([]);
  const [deprecatedProgramsUntil, setAvailableUntilDate] = useState<string>("");
  const [paymentMethodLink, setpaymentMethodLink] = useState<string>("");
  const [lastUserStatsUpdateValue, setLastUserStatsUpdateValue] = useState("");
  const lastUserStatsUpdate = useDebouncedValue(lastUserStatsUpdateValue, 500);
  const [userCoins, setUserCoins] = useState(0);
  const [userLevel, setUserLevel] = useState(0);
  const [userExperience, setUserExperience] = useState(0);
  const isAdmin = user?.roles.includes(UserRole.Admin);
  const hasRoles = (roles: Array<UserRole>) =>
    roles.some((role) => user?.roles.includes(role));

  const [storedProMode, setStoredProMode] = useLocalStorage(
    config.PRO_MODE,
    hasRoles([UserRole.Pro])
  );
  const { data: myOrders } = useQuery(GetMyOrdersDocument, {
    variables: {},
    fetchPolicy: "no-cache",
  });

  const { data: programsToDeprecate } = useQuery(SearchProgramsDocument, {
    variables: { programSearchInput: { notifyInBanner: true } },
    fetchPolicy: "no-cache",
  });

  const [geolocation, setGeolocation] = useState<GeoLocation>({
    country: "US",
    nameTranslated: "United States",
    location: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  const [firebaseAnalyticsApp, setFirebaseAnalyticsApp] =
    useState<FirebaseApp>();

  useEffect(() => {
    const dateTime: DateTimeInterface = new DateTimeMoment();

    dateTime
      .getGeoLocation()
      .then(setGeolocation)
      .catch((error) => {
        SBErrorPubSub.publish({
          component: "UserProvider.tsx line 133",
          message: (error as Error)?.message || t("Error getting geolocation"),
          showInProd: true,
        });
      });
  }, []);

  const [newRoles, setNewRoles] = useState<UserRole[]>(user?.roles || []);
  const [updateFormData, setUpdateFormData] = useState<any>();
  /*const [
    updateUser,
    { data: updateUserData, loading: isUpdating, error: updateError },
  ] = useMutation(UpdateUserDocument, {
    errorPolicy: "all",
    update: (cache) => {
      if (user) {
        cache.evict({ id: `User:${user.id}` });
      }
    },
  });*/
  const handleSaveProfile = () => {
    const updatedUserFormData: UpdateUserInput = {
      id: user?.id || "",
      roles: newRoles,
    };
    if (updatedUserFormData) {
      setUser((prevState: any) => {
        if (prevState) {
          return {
            ...prevState,
            roles: newRoles,
          };
        }
        return prevState;
      });
    }
    /*if (updateFormData) {
      updateUser({
        variables: {
          updateUserInput: updateFormData,
        },
      });
    }*/
  };

  const handleRoleChange = () => {
    setUpdateFormData(user);
    if (newRoles.includes(UserRole.Pro)) {
      const indexToRemove = user?.roles.indexOf(UserRole.Pro) || -1;
      if (indexToRemove !== -1) {
        setNewRoles((prevState) => {
          const newState = [...prevState];
          newState.splice(indexToRemove, 1);
          return newState;
        });
      }
    } else {
      setNewRoles((prevState) => [...prevState, UserRole.Pro]);
    }
    setUpdateFormData((prevState: any) => {
      if (prevState) {
        return {
          ...prevState,
          roles: newRoles,
        };
      }
      return prevState;
    });
  };

  const [isProModeEnabled, setIsProModeEnabled] =
    React.useState<boolean>(storedProMode);

  /*useEffect(() => {
    setUser(updateUserData?.updateUser || user);
  }, [updateUserData]);*/

  useEffect(() => {
    setNewRoles(user?.roles || []);
  }, [user?.roles]);

  useEffect(() => {
    handleSaveProfile();
  }, [updateFormData]);

  useEffect(() => {
    if (!user) return;
    if (firebaseConfig.projectId) {
      setFirebaseAnalyticsApp(initializeApp(firebaseConfig));
    }
  }, [user, firebaseConfig]);

  useEffect(() => {
    if (myOrders?.getMyOrders) {
      const lastOrder = myOrders?.getMyOrders[0];
      if (lastOrder?.isPaymentRejected) {
        setLastPaymentDeclined(true);
      }
      if (lastOrder?.paymentMethodLink) {
        setpaymentMethodLink(lastOrder?.paymentMethodLink);
      }
    }
  }, [myOrders]);

  useEffect(() => {
    if (
      programsToDeprecate?.searchPrograms &&
      programsToDeprecate?.searchPrograms.length > 0
    ) {
      const onlyTitles = programsToDeprecate?.searchPrograms.flatMap(
        (_) => "Diplomado en " + _.name
      );
      setAvailableUntilDate(
        programsToDeprecate?.searchPrograms[0].availableUntil?.toString() || ""
      );
      setProgramsToDeprecateTitles(onlyTitles);
    }
  }, [programsToDeprecate]);

  const value = React.useMemo<UserHook>(
    () => ({
      user,
      timezone,
      setTimezone: (val: string) => {
        setStoredTimezone(val);
        return setTimezone(val);
      },
      setUser,
      setUserCoins,
      userCoins,
      userExperience,
      setUserExperience,
      setUserLevel,
      userLevel,
      isAdmin,
      hasRoles,
      isEditModeEnabled,
      isProModeEnabled,
      communityWhatsApp: {
        amazon: user?.communityWhatsApp?.amazon || false,
        meli: user?.communityWhatsApp?.meli || false,
        dropshipping: user?.communityWhatsApp?.dropshipping || false,
      },
      setIsProModeEnabled: (val: SetStateAction<boolean>) => {
        handleRoleChange();
        setStoredProMode(val === true);
        return setIsProModeEnabled(val);
      },
      setIsEditModeEnabled: (val: SetStateAction<boolean>) => {
        setStoredEditMode(val === true);
        return setIsEditModeEnabled(val);
      },
      isMenuVisible,
      setIsMenuVisible,
      recentCoursesIds,
      addRecentCourse: (courseId: string) => {
        const firstUniqueIds = Array.from(
          new Set([courseId, ...storeRecentCoursesIds])
        )
          .slice(0, 5)
          .sort((leftCourseId, rightCourseId) =>
            leftCourseId === courseId ? -1 : rightCourseId === courseId ? 1 : 0
          );
        setRecentCoursesIds(firstUniqueIds);
        setStoreRecentCoursesIds(firstUniqueIds);
      },
      lastUserStatsUpdate,
      refetchUserStats: (delay = 0) => {
        setTimeout(() => {
          setLastUserStatsUpdateValue(Date.now().toString(10));
        }, delay);
      },
      isUnlockedForUser: (requiredLevel: number) => {
        return userLevel >= requiredLevel;
      },
      currentSchool,
      geolocation,
      setCurrentSchool,
      setTheme,
      theme,
      firebaseAnalyticsApp,
      lastPaymentDeclined,
      setLastPaymentDeclined,
      paymentMethodLink,
      setpaymentMethodLink,
      setProgramsToDeprecateTitles,
      setAvailableUntilDate,
      deprecatedProgramsUntil,
      programsToDeprecateTitles,
    }),
    [
      user,
      currentSchool,
      theme,
      isEditModeEnabled,
      isProModeEnabled,
      timezone,
      isMenuVisible,
      recentCoursesIds,
      lastUserStatsUpdate,
      userCoins,
      userLevel,
      userExperience,
      firebaseAnalyticsApp,
      lastPaymentDeclined,
      paymentMethodLink,
      programsToDeprecateTitles,
      deprecatedProgramsUntil,
    ]
  );
  return <UserContext.Provider value={value} {...props} />;
};
