import { useUser } from "@/providers/useUser";
import { Author } from "@/schemaTypes";
import { Box, Flex, FlexProps, Text } from "@chakra-ui/react";

const getInitials = (name: string) =>
  name
    .split(" ")
    .map((word) => word[0])
    .join("")
    .toUpperCase();

const AvatarForum = ({
  w,
  fs,
  author,
  ...props
}: FlexProps & {
  fs?: string;
  author?: Author;
}): JSX.Element => {
  const { user } = useUser();
  const defaultName = `${user?.name ?? ""} ${user?.lastName ?? ""}`;
  const initials = getInitials(author?.name ?? defaultName);

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      borderRadius="100%"
      bgColor={
        author?.id && author?.id !== user?.id ? "secondary.300" : "primary.300"
      }
      width={w ?? "40px"}
      height={w ?? "40px"}
      color="shades.white"
      px={4}
      {...props}
    >
      <Box w="21px" h="23px">
        <Text
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontFamily="Inter"
          fontSize={fs ?? "14px"}
          fontStyle="normal"
          fontWeight="600"
          lineHeight="22.4px"
        >
          {initials}
        </Text>
      </Box>
    </Flex>
  );
};

export default AvatarForum;
